<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{survey.title}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.surveys.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item
                        v-for="(question, index) in survey.questions"
                        :key="question.id"
                    >
                        <v-list-item-icon>
                            <v-icon
                                color="pink"
                            >
                                mdi-star
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-html="survey.questions[index].text" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Survey from "@/models/survey";

    export default {
        name: "surveys-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                survey: {}
            }
        },
        async mounted() {
            const surveyId = this.$route.params.id
            this.survey = await Survey.find(surveyId)
        },
    }
</script>

<style scoped>

</style>
